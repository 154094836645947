import { Button, Paper } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { logout, useAuthDispatch, useAuthState } from "../../context";

const Header = (props) => {
  const user = useAuthState();

  const dispatch = useAuthDispatch();
  let navigate = useNavigate();

  const handleLogout = (e) => {
    logout(dispatch);
    try {
      props.socket.disconnect();
      // console.log("Disconnecting socket");
    } catch (e) {
      console.log("Error Disconnecting socket: ", e);
    }

    navigate("/login", { replace: true });
  };

  return (
    <div>
      <Paper
        style={{ backgroundColor: "#15293C" }}
        variant="outlined"
        square
        className="header-component"
      >
        <div
          style={{
            display: "flex",
            height: "3.5rem",

            padding: "0.5rem",
          }}
        >
          <img src={"/assets/Infinity_log_white.png"} alt="INFINITY BOX"></img>
        </div>

        {user.token !== "" && (
          <Button
            style={{
              fontFamily: "Poppins",
              height: "2.75rem",
              margin: "0.85rem",
              borderRadius: "0.75rem",
            }}
            variant="contained"
            color="success"
            onClick={handleLogout}
          >
            Logout
          </Button>
        )}
      </Paper>
    </div>
  );
};

export default Header;
